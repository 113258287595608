<template>
    <v-dialog v-model="showDialog" persistent width="500px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon>{{ icon }}</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-title>{{ title }}</v-card-title>
            <v-card-text>
                <slot name="body"/>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn text @click="cancel()">Cancel</v-btn>
                <v-btn color="primary" text @click="save()">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {updateQuestionOptionField} from "@/modules/forms/helpers/helpers";

export default {
    name: "KurccFormMultiChoiceAdditionalButtonsDialogTemplate",
    props: {
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            showDialog: false,
            updateQuestionOptionField
        }
    },
    methods: {
        close() {
            this.showDialog = false
        },
        cancel() {
            this.$emit('cancel')
            this.close()
        },
        save() {
            this.$emit('save')
            this.close()
        }
    }
}
</script>
